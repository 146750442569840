export default {
    "home":"home",
    "about":"about",
    "skills":"skills",
    "portfolio":"portfolio",
    "paper": "Papers",
    "contact":"Contact",
    "blog": "blog",
    "home__title": "Hi, I'm Jiang!",
    "home__subtitle":"Web Developer",
    "home__description": "Enthusiast for new things, eager to change the world using technology, and enjoying life",
    "home__contact": "Contact Me",
    "home__scroll-name": "Scroll Down",
    "about__title": "About Me",
    "about__subtitle": "Brief introduction",
    "about__description": "I'm a web developer who moved from China and currently works in Japan. During my university years, I majored in Physics and later pursued a Master's in Information Science at Osaka University. My graduate research focused on using natural language processing techniques for the retrieval of Classical Chinese texts. Currently, I work as a web developer with experience in backend and frontend development, deployment, and operations. I also keep an interest in large language models and machine learning.",
    "download": "Download CV",
    
    "paper__title": "Papers",
    
    "skills__title": "Skills",
    "skills__subtitle": "Technical levels",
    "skills__years": "More than 1 years",
    "skill__title1": "Backend developer",
    "skill__years1": "More than 3 years",
    "skill__title2": "Frontend developer",
    "skill__years2": "More than 1 years",
    "skill__title3": "Cloud developer",
    "skill__years3": "More than 1 years",
    "skill__title4": "Machine learning",
    "skill__years4": "More than 1 years",
    
    "qualification__title": "Qualifications",
    "qualification__subtitle": "My background",
    "education": "Education",
    "work": "Work",
    "qualification1__title": "Bachelor of Physics",
    "qualification1__describe": "Ibaraki University, Faculty of Science",
    "qualification2__title": "Master of Information Science",
    "qualification2__describe": "Osaka University, Graduate School of Information Science and Technology",
    "qualification3__title": "Research Assistant",
    "qualification3__describe": "Osaka University, Graduate School of Information Science and Technology",
    "qualification4__title": "Backend Engineer",
    "qualification4__describe": "Intern at teamLab, Tokyo",
    "qualification5__title": "Teaching Assistant",
    "qualification5__describe": "Osaka University, Graduate School of Information Science and Technology",
    "qualification6__title": "Software Engineer",
    "qualification6__describe": "MonotaRO Co., Ltd.",
    "portfolio__title":"Portfolio",
    "portfolio__subtitle":"Most recent works",
    "portfolio1__title":"Login System",
    "portfolio1__description":"A login system built using Spring-boot and Vue3, which maintains the login state by utilizing JWT.",
    "contact__title":"Contact me",
    "contact__subtitle":"Get in touch",
    "email":"E-mail",
    "email__address":"jiangzhongqing AT outlook.com",
    "location":"Location",
    "location__detail":"Osaka, Japan",
}