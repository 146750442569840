export default {
    "home":"Home",
    "about": "About",
    "skills": "Skills",
    "paper": "Papers",
    "contact": "Contact",
    "blog": "博客",
    "home__title":"Hi, I'm Jiang!",
    "home__subtitle":"Web Developer",
    "home__description": "热衷于新事物，渴望运用技术改变世界，享受生活",
    "home__contact": "联系我",
    "home__scroll-name": "向下滑",
    "about__title": "关于我",
    "about__subtitle": "简短介绍",
    "about__description": "我是一名在日本工作的开发者。在大学期间，我主修物理学，后来在大阪大学继续攻读信息科学硕士学位。在研究生阶段，我主要研究使用自然语言处理技术检索古汉语文本。目前，我从事软件开发工作，拥有后端和前端开发、部署及运维的经验。同时我对大型语言模型和机器学习也保持着浓厚的兴趣。",
    "download": "简历",
    
    "paper__title": "论文",
    
    "skills__title": "技能",
    "skills__subtitle": "技术水平",
    "skills__years": "超过1年",
    "skill__title1": "后端开发",
    "skill__years1": "More than 3 years",
    "skill__title2": "前端开发",
    "skill__years2": "More than 1 years",
    "skill__title3": "云开发",
    "skill__years3": "More than 1 years",
    "skill__title4": "机器学习",
    "skill__years4": "More than 1 years",
    
    "qualification__title": "资历",
    "qualification__subtitle": "我的背景",
    "education": "教育",
    "work": "工作",
    "qualification1__title": "物理学 学士",
    "qualification1__describe": "茨城大学理学部",
    "qualification2__title": "情報科学 修士",
    "qualification2__describe": "大阪大学情報科学研究科",
    "qualification3__title": "Research Assistant",
    "qualification3__describe": "大阪大学情報科学研究科",
    "qualification4__title": "JAVA后端工程师",
    "qualification4__describe": "intern at teamLab, Tokyo",
    "qualification5__title": "Teaching Assistant",
    "qualification5__describe": "大阪大学情報科学研究科",
    "qualification6__title": "Software Engineer",
    "qualification6__describe": "MonotaRO Co., Ltd.",
    "portfolio__title":"项目集",
    "portfolio__subtitle":"近期项目",
    "portfolio1__title":"Login System",
    "portfolio1__description":"使用Spring-boot和Vue3构建的前后端分离的登录系统，使用JWT验证登录状态。",


    "contact__title":"联系方式",
    "contact__subtitle":"与我联系",
    "email":"电子邮箱",
    "email__address":"jiangzhongqing AT outlook.com",
    "location":"位置",
    "location__detail":"大阪府"
    
}