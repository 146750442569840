export default {
    "home":"Home",
    "about": "About",
    "skills": "Skills",
    "paper": "Papers",
    // "portfolio": "ポートフォリオ",
    "contact": "Contact",
    "blog": "ブログ",
    "home__title": "Hi, I'm Jiang!",
    "home__subtitle":"Web Developer",
    "home__description": "新しいモノ好き、技術を用いて世界を変えたい、人生エンジョイしている",
    "home__contact": "私に連絡する",
    "home__scroll-name": "Scroll Down",
    "about__title": "私について",
    "about__subtitle": "簡単な紹介",
    "about__description": "中国から渡ってきたエンジニア。大学時代は物理学を専攻し、その後大阪大学情報科学研究科で情報科学修士を取得。大学院では自然言語処理技術を用いて漢文テキスト検索に関する研究に取り組んでました。現在はエンジニアとして働いています。バックエンド、フロントエンドの開発、デプロイ、運用の経験があり、LLMや機械学習にも興味を持っています。",

    "download": "履歴書",

    "paper__title": "論文",

    "skills__title": "スキル",
    "skills__subtitle": "技術レベル",
    "skills__years": "More than 1 years",
    "skill__title1": "バックエンド開発",
    "skill__years1": "More than 3 years",
    "skill__title2": "フロントエンド開発",
    "skill__years2": "More than 1 years",
    "skill__title3": "クラウド開発",
    "skill__years3": "More than 1 years",
    "skill__title4": "機械学習",
    "skill__years4": "More than 1 years",

    "qualification__title": "履歴",
    "qualification__subtitle": "私の履歴",
    "education": "学歴",
    "work": "職歴",
    "qualification1__title": "物理学 学士",
    "qualification1__describe": "茨城大学理学部",
    "qualification2__title": "情報科学 修士",
    "qualification2__describe": "大阪大学情報科学研究科",
    "qualification3__title": "Research Assistant",
    "qualification3__describe": "大阪大学情報科学研究科",
    "qualification4__title": "バックエンドエンジニア",
    "qualification4__describe": "intern at teamLab, Tokyo",
    "qualification5__title": "Teaching Assistant",
    "qualification5__describe": "大阪大学情報科学研究科",
    "qualification6__title": "ソフトウェアエンジニア",
    "qualification6__describe": "MonotaRO Co., Ltd.",
    "portfolio__title": "ポートフォリオ",
    "portfolio__subtitle": "最近のプロジェクト",
    "portfolio1__title": "Login System",
    "portfolio1__description": "Spring-bootとVue3を使って構築したログインシステム、JWTを使ってログイン状態の維持を実現した。",


    "contact__title":"連絡先",
    // "contact__subtitle":"与我联系",
    "email":"メール",
    "email__address":"jiangzhongqing AT outlook.com",
    "location":"ローケーション",
    "location__detail":"大阪府"
}
